import React, { useState, useRef } from 'react';
import ReviewsCarousel from './ReviewsCarousel';
import Dropdown from './ModelDropdown';
import {
    appendNewChatMessage,
    appendToLastChatMessage,
    showChatMessages,
    handleSend,
    handleMicClick,
    handleTextModeClick,
    startRecording,
    stopRecording,
    useWebSocket
} from './main_utils';

function MainForm() {
    const [selectedModel, setSelectedModel] = useState('Gemini Context Cached');
    const [message, setMessage] = useState('');
    const [isWaiting, setIsWaiting] = useState(false); // State to manage waiting status
    const dotsRef = useRef(null); // Ref for the dots
    const socketRef = useRef(null);
    const retryCountRef = useRef(0);
    const [showMeelaAvatar, setShowMeelaAvatar] = useState(false);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const [isRecording, setIsRecording] = useState(false); // State variable to track recording status

    useWebSocket(socketRef, retryCountRef, setIsWaiting, appendNewChatMessage, appendToLastChatMessage);

    return (
        <div className="background-image-container justify-content-center align-items-center">
            <div className="overlay"></div>
            <div className="container content p-5">
                <div className="row">
                    <div className="col-md-9">
                        <div className="d-flex align-items-center justify-content-end model-logo-row">
                            {/* Dropdown */}
                            {/* <Dropdown setSelectedModel={setSelectedModel}/> */}
                            {/* Image */}
                            <img src="logo.png" alt="Commune Logo" className="img-fluid" style={{ width: 'auto', height: '20px' }} />
                        </div>
                    </div>
                    <div className="col-md-3">
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-9">
                        {/* Chat window content */}
                        <div className="chat-window text-chat-window">
                            {showMeelaAvatar && (
                                <div className="meela-avatar text-center">
                                    <img src="meela.gif" alt="Meela Avatar" className="img-fluid" />
                                </div>
                            )}
                            {isWaiting && (
                                <div className="dots text-left" ref={dotsRef}>
                                    <span>.</span>
                                    <span>.</span>
                                    <span>.</span>
                                </div>
                            )}
                        </div>

                        <div className="chat-input black-background p-3">
                            <div className="row w-100">
                                {!showMeelaAvatar && (
                                    <>
                                        <div className="row">
                                            {/* Input field, occupies full width on mobile */}
                                            <div className="col-md-10 col-12 mb-2">
                                                <input
                                                    type="text"
                                                    className="form-control black-background-input"
                                                    placeholder="Type a message..."
                                                    value={message}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                        handleSend(message, socketRef, appendNewChatMessage, setIsWaiting, setMessage, selectedModel);
                                                        }
                                                    }}
                                                    style={{ border: 'none', outline: 'none', boxShadow: 'none', color: '#fff', backgroundColor: '#000' }}
                                                />
                                            </div>

                                            {/* Buttons, occupy full width on mobile */}
                                            <div className="col-md-2 col-12 d-flex justify-content-start">
                                                <button
                                                    className="d-flex align-items-center green-btn me-2"
                                                    onClick={() => handleSend(message, socketRef, appendNewChatMessage, setIsWaiting, setMessage, selectedModel)}
                                                    >
                                                    Send <i className="fas fa-paper-plane mic-icon"></i>
                                                </button>
                                                <button
                                                    className="btn rounded-pill"
                                                    style={{ padding: '0px' }}
                                                    onClick={() => handleMicClick(setShowMeelaAvatar)}
                                                    >
                                                    <img src="mic-btn-2.png" alt="Microphone" style={{ height: '24px', width: '24px', marginLeft: '10px' }} />
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {/* show push-to-talk button otherwise */}
                                {showMeelaAvatar && (
                                    <div className="col-md-12 d-flex justify-content-between justify-content-md-center align-items-center position-relative">
                                        <button
                                            className={`btn rounded-pill ${isRecording ? 'recording' : 'btn-success green-btn-2'}`}
                                            onMouseDown={() => startRecording(setIsRecording, mediaRecorderRef, audioChunksRef, socketRef)}
                                            onMouseUp={() => stopRecording(mediaRecorderRef)}
                                        >
                                        {isRecording ? (
                                            <div className="recording-indicator">
                                                <div className="recording-dot"></div>
                                                Recording...
                                            </div>
                                        ) : (
                                            <>Press to talk <img src="mic-btn.png" alt="Microphone" style={{ height: '20px', width: '14px', marginBottom: '5px' }} /></>
                                        )}
                                        </button>
                                        <button
                                            className="btn keyboard-btn position-absolute"
                                            onClick={() => handleTextModeClick(setShowMeelaAvatar, showChatMessages)}
                                        >
                                            <img src="keyboard-btn.png" alt="text-mode"/>
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="text-center">
                            <ReviewsCarousel />
                            <p className="mt-1" style={{color: '#9DF4AB'}}>
                                This experience is powered by the smart people at <a href="https://6thsolution.com/" className="text-white fw-bold">6thSolution Inc.</a><br></br>
                                Want something like this for your next project? <a href="https://6thsolution.com/contact-us/" style={{color: '#9DF4AB'}}>Get in touch.</a>
                            </p>
                        </div>

                    </div>

                    <div className="col-md-3">
                        <div className="info-panel">
                            <div className='text-left'>
                                <h3 style={{fontSize: '22px', whiteSpace: 'nowrap'}}>A Character Comes to Life</h3>
                                <p style={{fontSize: '16px'}}>This is a one-of-a-kind experience for you to chat directly with a character from Ben Wakeman's spellbinding novel <span className='fw-bold'>THE MEMORY OF MY SHADOW</span>.</p>
                                <p>Meela is a digital companion from the year 2052 in a post-singularity world and she's prepared to tell all. You can text with her or have a voice chat.</p>
                            </div>
                            <img src="book.png" alt="Book Cover" className="img-fluid d-block mx-auto" style={{ width: 'auto', height: '250px', border: '1px solid white' }} />
                            <p className="mt-1" style={{fontSize: '16px'}}>Available worldwide wherever you read or listen to books</p>
                            <div className="mt-3 text-center">
                                <a href="https://www.lulu.com/shop/ben-wakeman/the-memory-of-my-shadow/paperback/product-kv87y7q.html?q=&page=1&pageSize=4" className="btn btn-sm btn-success rounded-pill m-2 green-btn-2">Buy Print Book</a>
                                <a href="https://www.lulu.com/shop/ben-wakeman/the-memory-of-my-shadow/ebook/product-m2wvw2k.html" className="btn btn-sm btn-success rounded-pill m-2 green-btn-2">Buy eBook</a>
                                <a href="https://www.audible.com/pd/The-Memory-of-My-Shadow-Audiobook/B0DFK3SPLJ?qid=1725565943&sr=1-1&ref_pageloadid=not_applicable&ref=a_search_c3_lProduct_1_1&pf_rd_p=83218cca-c308-412f-bfcf-90198b687a2f&pf_rd_r=6CYNY2V0D59XVQ7NC9GF&pageLoadId=vhyMXKFnhuq8HwjX&creativeId=0d6f6720-f41c-457e-a42b-8c8dceb62f2c" className="btn btn-sm btn-success rounded-pill m-2 green-btn-2">Buy Audio Book</a>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
}

export default MainForm;
