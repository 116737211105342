import React from 'react';
import { Carousel } from 'react-bootstrap';

const ReviewsCarousel = () => {
    return (
        <>
            <h3 className="fw-bold mt-2" style={{ color: '#9DF4AB' }}>Reader Reviews</h3>
            <Carousel>
                <Carousel.Item>
                    <p className="text-white font-italic fw-bold">
                        "I think that you wrote something fabulous, of the caliber of The Martian, by Andy Weir. It’s way better than the film Ex-Machina (which I liked a lot). All in all, a deeply satisfying read that will keep me busy in my thoughts for a very long time.”
                        <br /> — Claudia B.
                    </p>
                </Carousel.Item>
                <Carousel.Item>
                    <p className="text-white font-italic fw-bold">
                        "Wakeman asks the big questions by focusing on the emotions which drive us; grief, loss, and the search for healing are put up against the boundaries which keep us safe from each other. Heart and mind are stitched together and torn apart in this tale of dystopian reach. Prepare to have both opened. A perfect novel for our times."
                        <br /> — Eleanor Anstruther, author of A Perfect Explanation
                    </p>
                </Carousel.Item>
                <Carousel.Item>
                    <p className="text-white font-italic fw-bold">
                        "What a ride. Signature Ben: big themes, deftly drawn characters, strong story development, vivid writing, believable dialogue, gut punches."
                        <br /> — Caz H.
                    </p>
                </Carousel.Item>
                <Carousel.Item>
                    <p className="text-white font-italic fw-bold">
                        "The Memory of My Shadow is his crowning achievement thus far. The concept and characters are engaging. The entanglement of human memory, emotion, and technology is both a cautionary tale and a clever plotline."
                        <br /> — Phil C.
                    </p>
                </Carousel.Item>
                <Carousel.Item>
                    <p className="text-white font-italic fw-bold">
                        "The Memory of My Shadow is a tight and well-written thriller with believable characters, both human and not, and wonderfully descriptive prose. It's a timely exploration of the promises and dangers of A.I. and what it means to be human in a world where the lines are beginning to blur."
                        <br /> — David N.
                    </p>
                </Carousel.Item>
            </Carousel>
        </>
    );
};

export default ReviewsCarousel;
