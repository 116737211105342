import { useState } from 'react';

function Dropdown({ setSelectedModel }) {
    const [selectedModel, setSelectedModelLocal] = useState('Gemini Context Cached');

    const handleSelect = (model) => {
        setSelectedModelLocal(model); // Update the local state (for dropdown display)
        setSelectedModel(model); // Update the state in the parent component
    };

    return (
        <div className="dropdown">
            <button 
                className="btn btn-sm btn-success dropdown-toggle" 
                type="button" 
                id="dropdownMenuButton" 
                data-bs-toggle="dropdown" 
                aria-expanded="false">
                {selectedModel}
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li>
                    <button 
                        className="dropdown-item" 
                        onClick={() => handleSelect('Gemini Context Cached')}>
                        Gemini Context Cached
                    </button>
                </li>
                <li>
                    <button 
                        className="dropdown-item" 
                        onClick={() => handleSelect('Gemini FineTuned')}>
                        Gemini FineTuned
                    </button>
                </li>
                <li>
                    <button 
                        className="dropdown-item" 
                        onClick={() => handleSelect('OpenAI FineTuned')}>
                        OpenAI FineTuned
                    </button>
                </li>
            </ul>
        </div>
    );
}

export default Dropdown;
