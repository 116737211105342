import { useEffect } from 'react';
import { marked } from 'marked';

export function appendNewChatMessage(chat_window, sender, message, responseId, socketRef) {
    // Count existing messages
    const messageCount = chat_window.querySelectorAll('.chat-message').length;

    let date = new Date();
    let options = {
        month: '2-digit',   // MM
        day: '2-digit',     // DD
        year: 'numeric',    // YYYY
        hour: '2-digit',    // hh
        minute: '2-digit',  // mm
        hour12: true        // AM/PM format
    };

    let formattedDate = date.toLocaleString('en-US', options);
    formattedDate = formattedDate.replace(',', '').replace(/\//g, '-');

    let reactions_div = `<div class="reaction-icons" response_id="${responseId}">` + 
        '<span class="thumb-up"><i style="color:green;" class="fas fa-thumbs-up"></i></span>' + 
        '<span class="thumb-down"><i style="color:red;" class="fas fa-thumbs-down"></i></span>' + 
        '</div>';

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = `
        <div class="chat-message ${sender}">
            <div class="avatar-container">
                <img
                    src="${sender === 'user' ? 'user.gif' : 'meela.gif'}"
                    alt="User Avatar"
                    class="chat-avatar"
                />
                <span class="chat-user">${sender === 'user' ? 'you' : 'meela'}</span>
            </div>
            <div class="chat-content">
                <span class="chat-time">${formattedDate}</span>
                <p class="${sender === 'user' ? 'user-chat' : 'meela-chat'}">
                ${message}
                </p>
                ${messageCount > 0 && sender === 'meela' ? reactions_div : ''}
            </div>
        </div>
    `;

    // Append the newly created message div to the chat window
    chat_window.appendChild(tempDiv);

    // Scroll to the new message
    tempDiv.scrollIntoView({ behavior: 'smooth', block: 'end' });

    // Add click event listeners to the thumbs-up and thumbs-down buttons
    if (messageCount > 0 && sender === 'meela') {
        // Select the reaction icons
        const thumbUp = tempDiv.querySelector('.thumb-up');
        const thumbDown = tempDiv.querySelector('.thumb-down');

        // Function to disable reactions
        function disableReactions() {
            thumbUp.classList.add('reaction-disabled');
            thumbDown.classList.add('reaction-disabled');
        }

        // Add click event listeners to reactions
        thumbUp.addEventListener('click', () => {
            if (!thumbUp.classList.contains('reaction-disabled')) {
                disableReactions();
                if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
                    // Send the responseId over the socket
                    socketRef.current.send(JSON.stringify({ type: 'reaction', responseId: responseId, command: 'thumb-up' }));
                } else {
                    console.error('WebSocket is not open. Reaction not sent.');
                }
            }
        });

        thumbDown.addEventListener('click', () => {
            if (!thumbDown.classList.contains('reaction-disabled')) {
                disableReactions();
                if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
                    // Send the responseId over the socket
                    socketRef.current.send(JSON.stringify({ type: 'reaction', responseId: responseId, command: 'thumb-down' }));
                } else {
                    console.error('WebSocket is not open. Reaction not sent.');
                }
            }
        });
    }
}


export function appendToLastChatMessage(chat_window, token) {
    // Find all elements with the class 'chat-message'
    const chatMessages = chat_window.querySelectorAll('.chat-message');

    // Check if there are any chat messages
    if (chatMessages.length > 0) {
        // Get the last chat message element
        const lastMessage = chatMessages[chatMessages.length - 1];

        // Append the token to the content of the last message
        lastMessage.querySelector('.chat-content').querySelector('p').textContent += token;
        // Scroll to the updated message
        lastMessage.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
}

function htmlMarkLastMessage(chat_window) {
    // Find all elements with the class 'chat-message'
    const chatMessages = chat_window.querySelectorAll('.chat-message');

    // Check if there are any chat messages
    if (chatMessages.length > 0) {
        // Get the last chat message element
        const lastMessage = chatMessages[chatMessages.length - 1];
        const contentElement = lastMessage.querySelector('.chat-content').querySelector('p');

        // Get the text content of the last message
        let textContent = contentElement.textContent;

        // Replace '** **' with '<b></b>'
        // Adjust the regex if you need to handle different patterns or multiple occurrences
        textContent = textContent.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');

        // Set the innerHTML of the paragraph to the modified text
        contentElement.innerHTML = textContent;
    }
}


export const handleSend = (message, socketRef, appendNewChatMessage, setIsWaiting, setMessage, selectedModel) => {
    const chatWindow = document.querySelector('.chat-window');
    if (message.trim() && socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
        appendNewChatMessage(chatWindow, 'user', message, null, socketRef);
        socketRef.current.send(JSON.stringify({ data: message, model: selectedModel }));
        setIsWaiting(true); // Start showing dots when a message is sent
        setMessage('');
    } else {
        console.error('WebSocket is not open. Message not sent.');
    }
};


export const showChatMessages = () => {
    // Show all div elements with the 'chat-message' class
    const chatMessages = document.querySelectorAll('.chat-message');
    chatMessages.forEach(message => {
        message.style.removeProperty('display');
    });
};

// handleMicClick.js
export const handleMicClick = (setShowMeelaAvatar) => {
    // Show Meela's avatar in the center of the chat window and clear previous messages
    setShowMeelaAvatar(true);

    // Hide all div elements with the 'chat-message' class
    const chatMessages = document.querySelectorAll('.chat-message');
    chatMessages.forEach(message => {
        message.style.display = 'none';
    });

    const chatWindow = document.querySelector('.chat-window');
    if (chatWindow) {
        // chatWindow.innerHTML = ''; // Clear all elements in the chat window
        chatWindow.classList.remove('text-chat-window');
        chatWindow.classList.add('voice-chat-window');
    }
};


export const handleTextModeClick = (setShowMeelaAvatar, showChatMessages) => {
    setShowMeelaAvatar(false);
    const chatWindow = document.querySelector('.chat-window');
    if (chatWindow) {
        chatWindow.classList.remove('voice-chat-window');
        chatWindow.classList.add('text-chat-window');
    }
    showChatMessages();
};


export const startRecording = (
        setIsRecording,
        mediaRecorderRef,
        audioChunksRef,
        socketRef
    )  => {
    setIsRecording(true); // Set recording status to true
    navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
            mediaRecorderRef.current = new MediaRecorder(stream);
            audioChunksRef.current = [];

            mediaRecorderRef.current.ondataavailable = event => {
                audioChunksRef.current.push(event.data);
            };

            mediaRecorderRef.current.onstop = () => {
                setIsRecording(false); // Set recording status to false
                const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
                // Here, you can send the audioBlob to the backend
                // Example: socketRef.current.send(audioBlob);

                // Optionally, convert the Blob to base64 and send it
                const reader = new FileReader();
                reader.readAsDataURL(audioBlob);
                reader.onloadend = () => {
                    const base64data = reader.result;
                    socketRef.current.send(JSON.stringify({ audio: base64data }));
                };
            };

            mediaRecorderRef.current.start();
        })
        .catch(error => {
            console.error('Error accessing microphone:', error);
            setIsRecording(false); // Ensure recording status is reset on error
        });
};

export const stopRecording = (mediaRecorderRef) => {
    if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
    }
};


export const useWebSocket = (
    socketRef,
    retryCountRef,
    setIsWaiting,
    appendNewChatMessage,
    appendToLastChatMessage
) => {
    useEffect(() => {
        let message_stream = '';
        let pingInterval;

        const connectWebSocket = () => {
            if (socketRef.current) {
                console.log('WebSocket is already connected or connecting.');
                return;
            }

            const ws = new WebSocket('wss://meela-api.6thsolution.com/chat/1/');

            ws.onopen = () => {
                console.log('WebSocket Client Connected');
                socketRef.current = ws;
                retryCountRef.current = 0; // Reset the retry count on successful connection

                // Start sending pings every 30 seconds
                pingInterval = setInterval(() => {
                    if (socketRef.current.readyState === WebSocket.OPEN) {
                        socketRef.current.send(JSON.stringify({ command: 'ping' }));
                    }
                }, 30000);
            };

            ws.onmessage = (event) => {
                setIsWaiting(false); // Stop showing dots when a message is received
                const msg = JSON.parse(event.data);
                const chatWindow = document.querySelector('.chat-window');
                if (msg.message === 'completed.') {
                    message_stream = "";
                    htmlMarkLastMessage(chatWindow);
                } else {
                    if (message_stream === "") {
                        appendNewChatMessage(chatWindow, 'meela', msg.message, msg.id, socketRef);
                    } else {
                        appendToLastChatMessage(chatWindow, msg.message);
                    }
                    message_stream += msg.message;
                }
            };

            ws.onclose = (event) => {
                console.log('WebSocket Client Disconnected', event);
                
                if (pingInterval) {
                    clearInterval(pingInterval);
                }

                if (!event.wasClean) {
                    console.log('Reconnecting WebSocket...');
                    retryCountRef.current += 1;
                    setTimeout(connectWebSocket, Math.min(10000, (1 << retryCountRef.current) * 1000)); // Exponential backoff
                }
                socketRef.current = null; // Clear the socket reference
            };

            ws.onerror = (error) => {
                console.error('WebSocket Error:', error);
                ws.close(); // Force close on error
            };

            socketRef.current = ws;
        };

        if (!socketRef.current) {
            connectWebSocket();
        }

        return () => {
            if (socketRef.current) {
                socketRef.current.close();
                socketRef.current = null; // Clear the socket reference on unmount
            }
            if (pingInterval) {
                clearInterval(pingInterval);
            }
        };
    }, []); // Empty dependency array ensures this runs only once
};
